import React from 'react'
import {BsLinkedin,BsGithub} from "react-icons/bs";
import './footer.css';
import Logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <footer>
      {/* <a href='#' className='footer__logo'>Murad</a> */}
      <ul className='permalinks'>
        <li><a href='#' className=''>Home</a></li>
        <li><a href='#about' className=''>About</a></li>
        <li><a href='#expeirience' className=''>Expeirience</a></li>
        <li><a href='#portfolio' className=''>Portfolio</a></li>
        <li><a href='#contact' className=''>Contact</a></li>
      </ul>
      <div className='footer__socials'>
        <div>
          <a href="https://www.linkedin.com/in/murad-eissa-0b953318a/" target="_blank"><BsLinkedin/></a>
        </div>
        <img className='footer__logo-img' src={Logo} alt='logo'/>
        <div>
          <a href="https://github.com/muradissa" target="_blank"><BsGithub/></a>
        </div>
      </div>
      <div className='footer__copyrights'>
        <small>&copy; Murad Issa. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer