import React ,{ useState } from 'react';
import './portfolio.css';
import IMG1 from '../../assets/instalanchor.jpg';
import IMG2 from '../../assets/cryptotrading.jpg';
import IMG3 from '../../assets/park2.jpg';
import IMG4 from '../../assets/salsa 20.jpg';
import IMG5 from '../../assets/fastapi.png';
import IMG6 from '../../assets/moreprojects.png';
import IMGGym from '../../assets/gym.png';
import IMGTradingStar from '../../assets/tradingstar.png';
import IMGtictac from '../../assets/tictac.png';
import Popup from './popup/Popup';

const data= [
  {
    id:1,
    title :"TradingStar Platform",
    image:IMGTradingStar,
    description:[" it's platform for intelligent and automated trading! Whether you're an experienced trader or just starting out, our advanced trading bot offers a variety of customizable features and powerful algorithms to help you maximize your trading strategies and achieve success in the market."],
    github:"https://github.com/muradissa/Trading-Starts",
    privateRepository: true,
    linkedInPost:"https://www.linkedin.com/posts/murad-issa-0b953318a_technology-trading-share-activity-7058772841617383424-nnbt?utm_source=share&utm_medium=member_desktop"
  },
  {
    id:2,
    title :"GYM website",
    image:IMGGym,
    description:["Link to Website : https://murad-gym.pages.dev/"],
    github:"https://github.com/muradissa/Binance-Trading-bot-and-Telegram-Alert-System",
    privateRepository: true,
    linkedInPost:"https://www.linkedin.com/posts/murad-issa-0b953318a_fullstackdeveloper-jobseeker-opentonewopportunities-activity-7080954596080140288-C1uv?utm_source=share&utm_medium=member_desktop"
  },
  {
    id:3,
    title :"Tic Tac Toe",
    image:IMGtictac,
    description:["I've successfully built a game of Tic Tac Toe using Angular - a powerful front-end development framework that has become increasingly popular in recent years. The game allows users to play the classic two-player game on their browser."],
    github:"https://github.com/muradissa/Tic-Tac-Toe",
    privateRepository: false,
    linkedInPost:"https://www.linkedin.com/posts/murad-issa-0b953318a_angular-typescript-html-activity-7059515081243377664-j-1C?utm_source=share&utm_medium=member_desktop"
  },
  {
    id:4,
    title :"Final Project - R&D",
    image:IMG1,
    description:["The goal of the project is to enabling optimization of anchors’ positions for ground holding walls. For this purpose, the program simulates forces arising in such situations and calculates mechanical stresses based on finite element methods. Efficiency of various strategies of anchor placing will be studied. Improvements of such strategies will be searched by the machine learning methods (Monte Carlo simulations, q-learning, gradient descent, branch and bounds,). Reliability of the results are studied from the comparisons of the obtained results with the published ones for the real ground holding walls."],
    github:"https://github.com/muradissa/Final-Project",
    privateRepository: false,
    linkedInPost:"https://www.linkedin.com/posts/murad-issa-0b953318a_fullstackdeveloper-jobseeker-opentonewopportunities-activity-7080954596080140288-C1uv?utm_source=share&utm_medium=member_desktop"
  },
  {
    id:5,
    title :"Binance trading bot",
    image:IMG2,
    description:["A crypto trading bot It designed to support all spot exchange and be controlled via telegram, it contains back testing, plotting and money management . Trading bots allow crypto investors to automate buying and selling of positions based on key technical indicators. Bots implement specific trading strategies, competing to attain the highest 'win rate', or percentage of profitable trades."],
    github:"https://github.com/muradissa/Binance-Trading-bot-and-Telegram-Alert-System",
    privateRepository: true,
    linkedInPost:""
  },
  {
    id:6,
    title :"Go Nature",
    image:IMG3,
    description:["Parks manager system: during one of the coursers, I worked with team of five members to create fully functional system to manage the parks affairs. Engineering a real time system, which includes managing resource and financial matters for the client. The process required using the implementation of design patterns Singleton, Decorator, Observer."],
    github:"https://github.com/muradissa/GoNature",
    privateRepository: false,
    linkedInPost:""
  },
  {
    id:7,
    title :"Encryption with Salsa20",
    image:IMG4,
    description:["DH Generation of a mutual key in group of 3 users, texts encryption and decryption with Salsa20 in ECB mode + signature with RSA .In this work we present a symmetric encryption communication system between three members with the ability to recognize the identity of the sender"],
    github:"https://github.com/muradissa/Salsa20--DH3--RSA",
    privateRepository: false,
    linkedInPost:""
  },
  {
    id:8,
    title :"Microservices with FastAPI",
    image:IMG5,
    description:["A simple Microservices app using Python FastAPI with React on the frontend. Using RedisJSON as a Database and dispatch events with Redis Streams. RedisJSON is a NoSQL database just like MongoDB and Redis Streams is an Event Bus."],
    github:"https://github.com/muradissa/Microservices-with-FastAPI",
    privateRepository: true,
    linkedInPost:""
  },
]
const Portfolio = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const togglePopup = (id) => {
    setProjectId(id);
    setIsOpen(!isOpen);
  }

  return (
    <>
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
      
        {
          data.map(({id,image,title,github,linkedInPost}) => {
            return(
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title}/>
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta1">
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='_blank'>Github</a>
                  <a className='btn btn-primary' onClick={()=> togglePopup(id)} >Read More</a>
                </div>
{/* 
                <a href={github} className='btn' target='_blank'>Github</a>
                <a className='btn btn-primary' onClick={()=> togglePopup(id)} >Read More</a> */}
                {
                  linkedInPost !=="" &&   <a href={linkedInPost} className='portfolio__item-linkedin-post'>linkedIn Post</a>
                }
              
              </div>
            </article>)
          })
        }
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG6} alt=""/>
          </div>
          <h3 style={{textAlign:"center"}}>More Projects +</h3>
          <div className="more__projects" >
            <a href="https://github.com/muradissa?tab=repositories" className='btn' target='_blank'>Github repositories</a>
          </div>
        </article>
      </div>
    </section>
    { isOpen && 
      <Popup
          content={data.filter(project => project.id === projectId)}
          handleClose={togglePopup}
      />
    }
    </>
  )
}

export default Portfolio;