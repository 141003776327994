import './contact.css'
import {MdOutlineEmail} from "react-icons/md";
import {BsWhatsapp} from "react-icons/bs";
import React, { useRef,useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const [btnText,setBtnText] = useState('Send Message');
  const sendEmail = (e) => {
    setBtnText('Sending..')
    e.preventDefault();

    emailjs.sendForm('service_reke0y2', 'template_qteprvi', form.current, 'wf6kgANltuqu8-Rp6')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          setBtnText('Send Message');
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <section id='contact'>
      <h5>Get In touch</h5>
      <h2>Contant Me</h2>
      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>muradissa00@gmail.com</h5>
            <a href='mailto:muradissa00@gmail.com' target='_blank'>Send a message</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>WhatsAPP</h4>
            <h5>+972558866455</h5>
            <a href='https://api.whatsapp.com/send?phone=+97255866455' target='_blank'>Send a message</a>
          </article>
      
        </div>
        <form action="" ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='message' rows='6' placeholder='Your Message' required/>
          <div className='btn-center'>
            <button type='submit' className='btn btn-primary'>{btnText}</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact