import React from 'react'
import './about.css'
import MeAbout from '../../assets/murad4.jpg'
import {FaAward,FaGraduationCap} from "react-icons/fa";
import {VscFolderLibrary} from "react-icons/vsc";

const About = () => {
  return (
    <section id='about'>
      <h4>Get To Know</h4>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={MeAbout} alt="About Image"/>
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h4>Experience</h4>
              <small>1 Year Working</small>
            </article>

            <article className='about__card'>
              <FaGraduationCap className='about__icon'/>
              <h4>Bachelor’s</h4>
              <small>B.Sc -  Software Engineering</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h4>Project</h4>
              <small>10+ Completed</small>
            </article>
            
          </div>
          <p>
          Graduate Software Engineer and Full Stack Developer, highly experienced with both front and back end development of web and mobile applications,
           and equipped with tons of passion to create beautiful code and amazing UIs.<br/>So if you look for an amazingly passionate developer to join your team, I'll be more than happy to contact!
          </p>

          <a href='#contact' className="btn btn-primary">Let's Talk </a>
        </div>
      </div>
    </section>
  )
}

export default About