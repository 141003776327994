import React from 'react'
import './skills.css'
import {BsPatchCheckFill} from "react-icons/bs";

const programmingLanguages =['Python','Java','JavaScript','TypeScript','C#','C','HTML','CSS'];
const technologies =['Github','Gitlab','Docker','Linux','OOP','Data Structure','Machine learning','Networking'];

const Skills = () => {
  return (
    <section id='skills'>
      <h5>More Skills</h5>
      <h2>My Skills</h2>
      <div className='container skills__container'>
        <div className='skills__frontend'>
          <h3>Programming languages</h3>
          <div className="skills__content">
            {programmingLanguages.map(skill => {
              return (
                <article className='skills__details'>
                  <BsPatchCheckFill className='skills__details-icon'/>
                  <div>
                    <h4>{skill}</h4>
                  </div>
                </article>)
            })}
          </div>
        </div>
        <div className='skills__backend'>
          <h3>Technologies</h3>
          <div className="skills__content">
            {technologies.map(skill => {
              return (
                <article className='skills__details'>
                  <BsPatchCheckFill className='skills__details-icon'/>
                  <div>
                    <h4>{skill}</h4>
                  </div>
                </article>)
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Skills;