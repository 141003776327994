import React,{useRef,useState} from 'react';
import emailjs from 'emailjs-com';
import IMG1 from '../../../assets/resume22.gif';
import './popupcv.css'

const PopupCV = props => {
    const form = useRef();
    const [btnText,setBtnText] = useState('Request');
    const sendEmail = (e) => {
        setBtnText('Sending..')
        e.preventDefault();

        emailjs.sendForm('service_reke0y2', 'template_qteprvi', form.current, 'wf6kgANltuqu8-Rp6')
        .then((result) => {
            e.target.reset();
            setBtnText('Done');
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div className="popupcv-box">
            <div className="box">
                <span className="close-icon2" onClick={props.handleClose}>x</span>
                
                <div className="popupcv__container">
                <div className="popupcv__img">
                    <img src={IMG1}/>
                </div>
                <h2 >Request Resume</h2>
                <div className="popupcv__content">     
                    <form action="" ref={form} onSubmit={sendEmail}>
                        <input type='email' name='email' placeholder='Your Email' required/>
                        <textarea name='message' rows='6' placeholder='Your Message' value="Request CV" style={{display:"none"}}/>

                        <div className='btn-center'>
                            <button type='submit' className='btn btn-primary'>{btnText}</button>
                        </div>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default PopupCV
