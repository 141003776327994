import React from "react";
import './popup.css'
const Popup = props => {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>x</span>
        <h2 >{props.content[0].title}</h2>
        <div className="popup__container">
          <div className="popup__img">
            <img src={props.content[0].image} alt={props.content[0].title}/>
          </div>
          <div className="popup__content">     
              <p>{props.content[0].description}</p>
              <a href={props.content[0].github} className='btn' target='_blank'>Github</a>
              {
                  props.content[0].linkedInPost !=="" &&   <a href={props.content[0].linkedInPost} className='portfolio__item-linkedin-post'>linkedIn Post</a>
                }
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Popup;